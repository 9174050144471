@import url("https://fonts.googleapis.com/css?family=Muli");

body {
  margin: 0;
  padding: 0;
  /* font-family: 'Century Gothic', 'Muli', sans-serif !important; */
}

.navbar-menu-top{
  height: 0; /* Glitch fix nel caso di barra disattivata */
}

.table th, .text-wrap table th {
  text-transform: capitalize !important;
}


.dropdown-menu .dropdown-item{
  cursor: pointer;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active{
  color: #fff !important;
}


button.close > span{
  display: none;
}

a.card-options-collapse{
  cursor: pointer;
}

.form-control:focus {
  background-color: transparent !important;
}

/* Fix glitch decoration button link */
.btn-link, .btn-link:hover {
  text-decoration: none !important;
}

.link-nodecoration{
  text-decoration: none !important;
}
.link-nopointer{
  cursor: default !important;
}

.link-pointer,
.cursor-pointer{
  cursor: pointer !important;
}

.shadow-lg{
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}


.opacity-3{
  opacity: 0.3 !important;
}


.page-header{
  margin: 0.1rem 0 1rem !important;
}

@media (min-width: 768px){
  .page-content {
    margin: .7rem 0 !important;
  }
}

label.ant-segmented-item{
  margin-bottom: 0 !important;
}

.ant-btn-primary:not(:disabled) {
  border-color: #467fcf !important;
  background: #467fcf !important;
}